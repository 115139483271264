import React from "react"
import ReactPlayer from "react-player"
import BlockContent from "@sanity/block-content-to-react"
import { Link, graphql } from "gatsby"
import { format } from "date-fns"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PastEventsSelect from "../../components/pastEventsSelect"
import BeFeaturedBanner from "../../components/beFeaturedBanner"
import FeaturingSpeakerCard from "../../components/featuringSpeakerCard"
import transformingMobilityLogo from "../../images/logos/transforming_mobility_2021_recap.png"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { motion } from "framer-motion"

import webinarThumbnail from "../../images/featured/transforming_mobility_thumbnail.png"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

function Summit2021Page({ data }) {
  const site = (data || {}).site
  const sponsors = (data || {}).sponsors
  
  const otherHighlights = data.content.otherHighlightVideos || [];

  const swiperBreakpoints = {
    540: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
    1920: {
      slidesPerView: 5,
    },
  }

  const swiperAutoplay = {
    delay: 2500,
    disableOnInteraction: false,
  }

  return (
    <Layout title="programs">
      <Seo title="Virtual Premier Program" />
      <PastEventsSelect activeEvent={"summit2021"}/>
      <motion.div
        exitBeforeEnter={false}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="flex flex-col items-center justify-start pt-32 pb-48 w-full text-center bg-summit-2021-header bg-cover bg-center bg-no-repeat">
        <img
            className="px-16 w-auto lg:max-w-2xl"
            src={transformingMobilityLogo}
            alt="Transforming Mobility Logo"
          />
        </section>
        <section>
          <div className="flex flex-col items-center justify-end w-full" style={{transform:"translateY(-150px)"}}>
            <div className="w-2/3">
              <div className="w-full player-wrapper">
                <ReactPlayer
                className="react-player"
                width="100%"
                height="100%"
                  url={
                    data.content.mainHighlightVideo.feed
                  }
                />
              </div>
            </div>
            <div className="w-2/3 flex flex-col items-center py-12 -mb-36">
              <h1 className="font-bold text-solar-orange-dark text-4xl text-center">{data.content.introHeader}</h1>
              <p className="text-dark-100 text-xl mt-8 text-left sm:text-center">
                {data.content.introParagraph}
              </p>
            </div>
          </div>
        </section>
        <section className="py-20 w-full bg-light-100">
            <div className="flex flex-col items-center mx-auto w-10/12 max-w-3xl text-white">
          <h1 className="text-teal-dark font-bold text-3xl mb-8">
            2021 Summit Highlights
          </h1>
          {
            otherHighlights.map(
              webinar => (
                <Link to={`/resources/webinars?id=${webinar._id}`} className="w-full">
                  <div
                    className="related-webinar-min-h flex flex-col items-center justify-center mb-4 md:flex-row md:items-stretch"
                    key={webinar._id}
                  >
                    <img
                      src={
                        webinar.thumbnail?.asset?.url ?? webinarThumbnail
                      }
                      alt="thumbnail"
                      className="w-full md:w-72 object-cover object-center"
                    />
                    <div className="inset-0 flex flex-col justify-center p-6 bg-white w-full">
                      <p className="text-lg font-semibold text-black">{webinar.name}</p>
                      <div className="line-clamp-2 text-black">
                        <BlockContent
                          blocks={webinar.description?.map(i => {
                            i.markDefs = []
                            return i
                          })}
                        />
                      </div>
                      <p className="flex items-center mt-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.5547 5.16795C7.24784 4.96338 6.8533 4.94431 6.52814 5.11833C6.20298 5.29235 6 5.63121 6 6V10C6 10.3688 6.20298 10.7077 6.52814 10.8817C6.8533 11.0557 7.24784 11.0366 7.5547 10.8321L10.5547 8.8321C10.8329 8.6466 11 8.3344 11 8C11 7.66565 10.8329 7.35342 10.5547 7.16795L7.5547 5.16795Z"
                            fill="black"
                          />
                        </svg>
                        <span className="ml-2 text-black text-sm">
                          {format(
                            new Date(webinar.date ?? null),
                            "MMMM d, yyyy"
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </Link>
              )
            )
          }
          </div>
        </section>
        <section className="flex flex-col items-center justify-start pt-32 pb-48 w-full text-center bg-resources-page-header-colorized bg-cover bg-center bg-no-repeat">
        <h1 className="text-white font-bold text-4xl mb-4">Want More?</h1>
        <Link to="/resources?q=summit2021">
          <button className="bg-white text-solar-orange-dark font-bold text-lg md:text-2xl px-8 py-3 rounded-full uppercase tracking-wider">
            View All Summit Resources
          </button>
        </Link>
        </section>
        <section className="flex flex-col justify-center w-full lg:flex-row">
          <div className="flex flex-col justify-center p-8 w-full text-center bg-gray-500 lg:w-1/4 lg:text-right">
            <h3 className="text-yellow-400 text-xl italic font-bold lg:text-2xl">
              Featuring
            </h3>
          </div>
          <div className="mx-auto p-4 w-full bg-gray-300 overflow-x-hidden">
            <Swiper
              slidesPerView={1}
              breakpoints={swiperBreakpoints}
              autoplay={swiperAutoplay}
            >
              {site &&
                site.edges[0].node.speakers.map((guest, i) => (
                  <SwiperSlide key={i}>
                    <FeaturingSpeakerCard
                      key={i}
                      name={guest.name}
                      position={guest.position}
                      image={guest.image.asset.url}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </section>
        <section className="py-8 w-full text-center bg-white lg:py-16">
          <h3 className="mb-4 text-gray-600 text-2xl font-bold lg:text-3xl">
            Our 2021 Sponsors
          </h3>
          <div className="fade-edges relative mx-auto w-full overflow-x-hidden lg:w-10/12">
            <Swiper
              slidesPerView={2}
              breakpoints={swiperBreakpoints}
              autoplay={swiperAutoplay}
              loop={true}
            >
              {sponsors &&
                sponsors.edges
                  .filter(
                    sponsor =>
                      sponsor.node.year.some(
                        element =>
                          element.value === new Date().getFullYear().toString()
                      ) &&
                      sponsor.node.sponsorTypes.some(
                        sponsor => sponsor.name === "Virtual Premier Event"
                      )
                  )
                  .map((sponsor, i) => (
                    <SwiperSlide key={i}>
                      <a
                        href={sponsor.node.company.url}
                        key={i}
                        target="_blank"
                        rel="noreferrer"
                        className="grayscale-effect relative block transition-all"
                      >
                        <img
                          src={sponsor.node.company.logo.asset.url}
                          alt={sponsor.node.company.name}
                          className="mx-auto w-32 h-16 object-contain object-center"
                        />
                      </a>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </section>
        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default Summit2021Page

export const query = graphql`
  {
  site: allSanityVirtualPremierEvent {
      edges {
        node {
          title
          speakers {
            bio
            company {
              url
              name
              logo {
                asset {
                  url
                }
              }
            }
            name
            position
            image {
              asset {
                url
              }
            }
          }
          description
          eventURL
        }
      }
    }
    sponsors: allSanitySponsor {
      edges {
        node {
          company {
            logo {
              asset {
                url
              }
            }
            url
            name
          }
          year {
            value
          }
          sponsorTypes {
            name
          }
        }
      }
    }
    content: sanityPastEvents {
      id
      introHeader
      introParagraph
      mainHighlightVideo {
        feed
      }
      otherHighlightVideos {
      _id
      name
      date
      description {
        children {
          marks
          text
          _key
          _type
        }
        list
        style
        _key
        _rawChildren
        _type
      }
      thumbnail {
        asset {
          url
        }
      }
    }
    }
  }
`
